import { useEffect, useRef, useState } from "react";
import { Mlistar, copyToClipboard, corColunaStatus, fire, getPermissao, toData } from "../../../../util";
import { Button, Col, Row } from "react-bootstrap-v5";
import MPaging from "../../../components/MPaging/MPaging";
import { tipoPermissao } from "../tipos/tipos";
import { MModal } from "../../../components/MModal/MModal";
import { MInput } from "../../../components/MInput/MInput";
import { getAxios } from "../../../components/net";
import { API_URL } from "../../../components/MConfig";
import Swal from "sweetalert2";
import { FromFormReference } from "../../../components/MForm/MForm";

export const ListaPropostas = props => {

    const paginador = useRef();
    const [dados, setDados] = useState([]);
    const modalCancelar = useRef();
    const [ciente, setCiente] = useState(false);
    const [propostaAtual, setPropostaAtual] = useState(false);


    const carregar = pagina => Mlistar("/proposta/ListarPropostas", paginador, pagina, null, res => setDados(res));

    const ordenar = (ordem) => Mlistar("/proposta/ListarPropostas", paginador, null, ordem, res => setDados(res));

    const editarCadastro = (e,x) =>{
        e?.stopPropagation();
        x.isEdit = true;
        props.editarCadastro(x);
    }

    const visualizar = (e, x) => {        
        e?.stopPropagation();
        fire.call('visualizar_proposta', x);
    }
    const copiar = (e, x) => {
        if (!x.linkKompletoCliente) {
            Swal.fire("Erro!", "Desculpe,algo errado com o link!!");
        } else {
            copyToClipboard(x.linkKompletoCliente);
            window.modalAlert.Show({ title: "Copiado", message: "Link copiado para a area de transferência", type: "info" });
        }
        e?.stopPropagation();
    }
    const cancelar = (e, x) => {        ;
        modalCancelar.current.Show(x);
        e?.stopPropagation();
    }

    const marcarCiente = e => {
        setCiente(e.target.checked);
    }

    const realizarCancelamento = e => {
        var dados = FromFormReference(e.current);
        dados.propostaId = dados.id;
        dados.cpf = dados.cpfenvio;
        delete dados.id;
        setCiente(false);
        getAxios().post(API_URL + "/proposta/cancelar", dados).then(res => {
            Swal.fire("Sucesso!", "Proposta cancelada!", "success");
        }).catch(res => {
            window.showWait(false);
            Swal.fire("Erro!", "<ul>" + JSON.stringify(res.response.data.message).split("\\r\\n").map(x => "<li>" + x + "<li>").join('') + "</ul>");
        });
    }

    const fecharModal = () => {
        setCiente(false);
    }


    useEffect(() => {        
        fire.register('filtrar_proposta', e => {
            paginador?.current?.setFilter(e);
        });

        if(props.recarregar){
            carregar(1);
        }

    }, [])

    useEffect(() => {        
            carregar(1);
    }, [props.recarregar])


    const filtroStatus = x =>{
        var ret = x.nomeStatus;
        if(x.produtoId == 6){
            ret += ' / ' + x.statusAdicional;
        }
        return ret;
    }


    return <>
        <MModal onClickCancel={fecharModal} onClickSave={realizarCancelamento} size={1.5} ref={modalCancelar} saveButton={ciente} title="Cancelar Proposta" closeText="Fechar" saveText="Confirmar Cancelamento" >
            <i className="fa fa-alert fa-2x"></i>
            <p>Deseja realmente cancelar a proposta <MInput type="label" id="id" /></p>
            <Row>
                <Col>Cpf: <br /> <MInput type="label" id="cpfenvio" /></Col>
                <Col>Cliente: <br /> <MInput type="label" id="nomeCliente" /></Col>
            </Row>
            <input className="mt-5" type="checkbox" onClick={e => marcarCiente(e)} /> Estou ciente, e desejo realmente cancelar.
            {ciente && <>
                <p>Preencha abaixo o motivo do cancelamento:</p>
                <MInput type="area" name="MotivoCancelamento" />
            </>}
            <input type="hidden" name="id" />
        </MModal>
        <div style={{ width: "100%", overflow: "auto" }}>
            <table id="tableList" className="table table-stripelist mt-5">
                <thead>
                    <tr>
                        <td></td>
                        <th>#</th>
                        <th><a href="#" onClick={e => ordenar('NomeCliente')}>Nome</a></th>
                        <th><a href="#" onClick={e => ordenar('Cpf')}>Cpf</a></th>
                        <th><a href="#" onClick={e => ordenar('TelefonePessoal')}>Telefone</a></th>
                        <th><a href="#" onClick={e => ordenar('Produto.NomeExterno')}>Produto</a></th>
                        <th><a href="#" onClick={e => ordenar('StatusId')}>Status</a></th>
                        <th><a href="#" onClick={e => ordenar('RevendedorId')}>Empresa</a></th>
                        <th><a href="#" onClick={e => ordenar('VendedorUsuarioId')}>Vendedor</a></th>
                        <th><a href="#" onClick={e => ordenar('DataCadastro')}>Data</a></th>
                        <th>Acoes</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        dados?.resultado?.map(x =>
                            <tr className="selecao" key={x.id} onClick={e =>visualizar(e, x)}>                                
                                <th className={`colunaStatus`} style={{backgroundColor : corColunaStatus(x.statusId)}} ></th>
                                <td>{x.id}</td>
                                <td>{x.nomeCliente}</td>
                                <td>{x.cpf}</td>
                                <td>{x.telefonePessoal}</td>
                                <td>{x.nomeProduto}</td>
                                <td>{filtroStatus(x)}</td>
                                <td>{x.nomeRevendedor}</td>
                                <td>{x.nomeVendedor}</td>
                                <td>{toData(x.dataCadastro)}</td>                                
                                <td style={{ zoom: 0.6 }} className="no-click" >
                                    <table>
                                        <td>
                                            <td><Button title="Editar" style={{ marginRight: 5 }} onClick={e =>  editarCadastro(e,x)}>
                                                <i className="fa fa-edit"  aria-hidden="true"></i>
                                            </Button>
                                            </td>
                                            <td style={{width:60}}>
                                                {x.linkAtivo && <Button title="Copiar link da proposta" className="btn-info" style={{ marginRight: 5 }} onClick={e => copiar(e, x)}>
                                                <i className="fa fa-clone"></i>
                                            </Button> }
                                            </td>
                                            <td>
                                                <Button title="Cancelar proposta" className="btn-danger" style={{ marginRight: 5 }} onClick={e => cancelar(e, x)}>
                                                    <i className="fa fa-trash"></i>
                                                </Button></td>
                                        </td>
                                    </table>

                                </td>
                            </tr>
                        )
                    }
                </tbody>
            </table>
            <MPaging data={dados} onGo={carregar} ref={paginador} />
        </div>
    </>

}
